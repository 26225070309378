.bg-radial-gradient {
  background: radial-gradient(circle, #a6a6a6, #515151);
}

.bg-footer-linear-gradient {
  background: linear-gradient(to bottom, #373737, #202020);
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.2em;
}

ul {
  list-style-type: circle;
}

ol {
  list-style-type: decimal;
}
